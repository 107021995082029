import * as React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import CardAppearAnimaton from "../animations/CardAppearAnimation"
import { useOnVisibleOnce } from "../hooks/useOnVisibleOnce"

const Card = styled(Link)({
    background: "linear-gradient(#FFEB3B, #FFB74D)",
    margin: "2em auto",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.8)",
    height: "7em",
    textAlign: "center",
    textDecoration: "none",
    display: "table",
    position: "relative",
    transition: "width 300ms, height 300ms",
    width: "80%",
    "&:hover": {
        width: "85%",
        height: "8em"
    }
}, ({ color1, color2, animate }) => ({
    background: `linear-gradient(${color1}, ${color2})`,
    animation: animate ? `${CardAppearAnimaton} 1.5s ease` : "none"
}))

const Title = styled.p({
    textTransform: "uppercase",
    fontSize: "1.7em",
    fontWeight: "500",
    letterSpacing: "0.06em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    verticalAlign: "middle",
    display: "table-cell",
    color: "#000"
})

export default function ProductMiniCard({ title, color1, color2, link }) {

    const [ref, animate] = useOnVisibleOnce()

    return (
        <Card ref={ref} animate={animate} color1={color1} color2={color2} to={link}>
            <Title>{title}</Title>
        </Card>
    )
}
