import { keyframes } from "@emotion/react"

export default keyframes`
    0% {
        opacity: 0%;
        top: -2em;
    }
    100% {
        opacity: 100%;
        top: 0;
    }
`
