import * as React from "react"
import styled from "@emotion/styled"
import Title from "../components/misc/Title"
import Paragraph from "../components/misc/Paragraph"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import SideAppearAnimation from "../animations/SideAppearAnimation"
import { useOnVisibleOnce } from "../hooks/useOnVisibleOnce"

const Wrapper = styled.div({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "2em",
    paddingBottom: "5em",
    borderBottom: "2px solid #999",
    position: "relative"
}, ({ orientation, animate }) => ({
    flexDirection: orientation === "side" ? "row-reverse" : "column",
    marginLeft: orientation === "side" ? "12%" : "20%",
    marginRight: orientation === "side" ? "12%" : "20%",
    animation: animate ? `${SideAppearAnimation("-100%", "left")} 1.5s ease` : "none"
}))

const TextWrapper = styled.div({

}, ({ orientation }) => ({
    flexBasis: orientation === "side" ? "40%" : "100%",
    "@media(max-width: 700px)": {
        flexBasis: "100%"
    }
}))

const Image = styled(GatsbyImage)({
    marginTop: "2.5em",
    borderRadius: "1em",
    boxShadow: "0 0 5px #000",
    transition: "width 300ms",
}, ({ orientation }) => ({
    width: orientation === "side" ? "40%" : "60%",
    "&:hover": {
        width: orientation === "side" ? "45%" : "65%"
    },
    "@media(max-width: 700px)": {
        width: "80%",
        "&:hover": {
            width: "90%"
        }
    }
}))

export default function HomeParagraph({ title, description, orientation, image }) {
    
    const imageData = getImage(image)
    const [ref, animate] = useOnVisibleOnce()

    return (
        <Wrapper ref={ref} animate={animate} orientation={orientation}>
            <TextWrapper orientation={orientation}>
                <Title>{title}</Title>
                <Paragraph>{description}</Paragraph>
            </TextWrapper>
            {image && <Image orientation={orientation} image={imageData} alt={title} />}
        </Wrapper>
    )
}
