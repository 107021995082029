import * as React from "react"
import MainContainer from "../components/MainContainer"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import HomeParagraph from "../components/HomeParagraph"
import MiniCard from "../components/MiniCard"
import SEO from "../components/SEO"

const Separator = styled.div({
    borderBottom: "2px solid #999",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%"
}, ({ marginTop }) => ({
    marginTop
}))

const Wrapper = styled.main({
    backgroundColor: "rgba(240, 240, 240, 0.85)",
    backdropFilter: "blur(5px)",
    paddingTop: "2em",
    paddingBottom: "5em"
})

const HeaderTitle = styled.p({
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    fontSize: "2.5em",
})

const CardsWrapper = styled.div({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "1.5em",
    "@media(max-width: 915px)": {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 635px)": {
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: "0.25em",
    }
})

export default function IndexPage() {

    const data = useStaticQuery(HomeData)

    const homeData = data.homeData.nodes
    const products = data.products.nodes
    const sectors = data.sectors.nodes
    const header = data.header
    const { seoTitle, seoDescription } = header

    const homeParagraphs = homeData.map(({ id, title, description, orientation, image }) =>
        <HomeParagraph key={id} title={title} description={description} orientation={orientation} image={image} />
    )

    const productCards = products.map(({ id, title, tag }) =>
        <MiniCard key={id} title={title} color1="#FFEB3B" color2="#FFB74D" link={`/products/${tag}`} />
    )

    const sectorCards = sectors.map(({ id, title, tag }) =>
        <MiniCard key={id} title={title} color1="#eee" color2="#767676" link={`/sectors/#${tag}`} />
    )

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="" />
            <Wrapper>
                <HeaderTitle>Nuestros productos</HeaderTitle>
                <Separator marginTop="0.7em" />
                <CardsWrapper>
                    {productCards}
                </CardsWrapper>
                <Separator marginTop="3em" />
                {homeParagraphs}
                <HeaderTitle>Sectores productivos</HeaderTitle>
                <Separator marginTop="0.7em" />
                <CardsWrapper>
                    {sectorCards}
                </CardsWrapper>
            </Wrapper>
        </MainContainer>
    )
}

const HomeData = graphql`
    query HomeData {
        homeData: allHomeJson {
            nodes {
                id
                title
                description
                orientation
                image {
                    childImageSharp {
                        gatsbyImageData(width: 600, height: 600)
                    }
                }
            }
        }
        products: allProductsJson {
            nodes {
                id
                title
                tag
            }
        }
        sectors: allSectorsJson {
            nodes {
                id
                title
                tag
            }
        }
        header: headersJson(id: {eq: "home"}) {
            seoTitle
            seoDescription
        }
    }
`
